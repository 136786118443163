import React from "react";
import Banner from "./components/banner/Banner";
import Profile from "./components/profile/Profile";



import FooterBottom from "./components/footer/FooterBottom";
import Navbar from "./components/navbar/Navbar";
import Projects from "./components/projects/Projects";
import Facilities from "./components/facilities/Faciities";


function App() {
  return (
    <div className="w-full h-auto bg-bodyColor text-lightText px-4">
        <Navbar />
      <div className="max-w-screen-xl mx-auto">
        <Banner />
        <Profile />
        <Facilities />
        <Projects />
       <FooterBottom />
      </div>
    </div>
  );
}

export default App;
