export const navLinksdata = [
  {
    _id: 1001,
    title: "Home",
    link: "home",
  },
  {
    _id: 1002,
    title: "Profile",
    link: "profiles",
  },
  {
    _id: 1002,
    title: "Facilities",
    link: "facilities",
  },
  {
    _id: 1003,
    title: "Projects",
    link: "projects",
  },
  
];