import React from 'react';


const Projects = () => {
  return (
    <section
    id="projects"
    className="w-full pt-10 pb-20 flex flex-col gap-10 xl:gap-0 lgl:flex-row items-center border-b-[1px] font-titleFont border-b-black"
  >
  <div name='support' className='w-full mt-24'>
      
      <div className='max-w-[1240px] mx-auto text-white relative'>
          <div className='px-4 py-2'>
              <h2 className='text-3xl  text-slate-300 uppercase text-center'>PROJECTS</h2>
                         
          </div>

          <div className='grid grid-cols-2  relative gap-x-8 gap-y-16 px-4 pt-4 sm:pt-6 text-black'>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                      <h3 className='font-bold text-xl my-6'>CLIENT:		SEMBCORP MARINE (SMIY) TUAS BOULEVARD YARD</h3>
                      <h5 className='font-bold text-base my-3'>PROJECT:		SHELL VITO AND JOHAN CASTBERG (2000 Ton)</h5>
                      <h5 className='font-bold text-base my-3'>SCOPE:		OUT FITTINGS</h5>
                     
                  </div>
                  
              </div>

              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                      <h3 className='font-bold text-xl my-6'>CLIENT:		KEPPEL SHIPYARD LTD (TUAS)</h3>
                      <h5 className='font-bold text-base my-3'>PROJECT:		GREATER ENFIELD DEVELOPMENT(GED)</h5>
                      <h5 className='font-bold text-base my-3'>SCOPE: TOP SIDE STRUCTURE FABRICATION ( 400 Ton) & PIPE SUPPORTS</h5>
                      <h5 className='font-bold text-base my-3'>DURATION:		MAY 2018 TO FEB 2019</h5>
                     
                  </div>

                  
              </div>

              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                  <h3 className='font-bold text-xl my-6'>CLIENT:		SEMCORP MARINE ADMIRALITY YARD (SMAY)</h3>
                      <h5 className='font-bold text-base my-3'>PROJECT:		KAOMBO FPSO NORTE AND SOUTH</h5>
                      <h5 className='font-bold text-base my-3'>SCOPE:FABRICATION AND ASSEMBLY - TOTAL 1200 TON</h5>
                      <h5 className='font-bold text-base my-3'>DURATION:		2015 TO 2018</h5>
                     
                  </div>
                  
              </div>
              
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                  <h3 className='font-bold text-xl my-6'>CLIENT:		PPL SHIPYARD (SEMB CORP MARINE)</h3>
                      <h5 className='font-bold text-base my-3'>PROJECT:		JACK UP DRILLING RIG P2032, P2035, P2038, P2040, P2043, P2046, P2049, P2050, P2055</h5>
                      <h5 className='font-bold text-base my-3'>SCOPE: LIVING QUARTERS, WING BLOCKS , OUT FITTINGS , DRILL FLOOR STRUCTURES</h5>
                      <h5 className='font-bold text-base my-3'>LOCATION:	PANDAN YARD, TUAS YARD, OMC, TONATO SHIPYARD, NIVI MARINE WORKSHOP</h5>
                      <h5 className='font-bold text-base my-3'>DURATION:		2011 TO 2015</h5>
                     
                  </div>
                  
              </div>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                  <h3 className='font-bold text-xl my-6'>CLIENT:		BAKER ENGINEERING</h3>
                      <h5 className='font-bold text-base my-3'>PROJECT:		BEPL 320 LIFT BOAT</h5>
                      <h5 className='font-bold text-base my-3'>SCOPE:ELECTRICAL AND OUT FITTINGS , WING BLOCKS , PIPING , SPUDCAN</h5>
                      <h5 className='font-bold text-base my-3'>LOCATION:	SEA DEEP YARD</h5>
                      <h5 className='font-bold text-base my-3'>DURATION:		2016 TO 2017</h5>
                     
                  </div>
                  
              </div>

              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                  <h3 className='font-bold text-xl my-6'>CLIENT:		DYNAMAC</h3>
                      <h5 className='font-bold text-base my-3'>PROJECT:		MODEC, OECAN VENTURE, BUMI ARMADA</h5>
                      <h5 className='font-bold text-base my-3'>MODULE STRUCTURE AND PIPING</h5>
                      <h5 className='font-bold text-base my-3'>LOCATION:	DYNAMAC YARD</h5>
                      <h5 className='font-bold text-base my-3'>DURATION:		2011 TO 2012</h5>
                     
                  </div>
                  
              </div>
 
 


  
 
          </div>
      </div>
  </div>
  </section>
  );
};

export default Projects;
