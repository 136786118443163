import React from 'react';


const Profile = () => {
  return (
    <section
    id="profiles"
    className="w-full pt-10 pb-20 flex flex-col gap-10 xl:gap-0 lgl:flex-row items-center border-b-[1px] font-titleFont border-b-black"
  >
  <div name='support' className='w-full mt-24'>
      
      <div className='max-w-[1240px] mx-auto text-white relative'>
          <div className='px-4 py-2'>
              <h2 className='text-3xl  text-slate-300 uppercase text-center'>Company Profile</h2>
                         
          </div>

          <div className='grid grid-cols-1  relative gap-x-8 gap-y-16 px-4 pt-4 sm:pt-6 text-black'>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                      <h3 className='font-bold text-xl my-6'>INCORPORATION AND SUBSIDIARIES</h3>
                      <p className='text-gray-600 text-lg '>YEAR INCORPORATED: <span className='px-12'>2019</span></p>
                      <br></br>
                      <p className='text-gray-600 text-lg '>SUBSIDIARIES:</p>
                      <br></br>
                      <ul>
                        <li>ADORNA ENGINEERING PTE LTD SINGAPORE: INCORPORATED	2014</li>
                        <br></br>
                        <li>FLOATATION PTE LTD SINGAPORE: INCORPORATED	   2011</li>
                      </ul>
                  </div>
                  
              </div>
              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                      <h3 className='font-bold text-xl my-6'>NATURE OF BUSINESS</h3>
                      <br></br>
                      <ul>
                        <li>Fabrication In Own Workshop (Pipping and Steel Work)</li>
                        <br></br>
                        <li>OFFSHORE / MARINE, AND ENVIRONMENTAL TECHNOLOGIES</li>
                        <br></br>
                        <li>EPC SERVICE PROVIDER FOR SUB SEA</li>
                        <br></br>
                        <li>RIG TARAT SPECIALISTS</li>
                        <br></br>
                        <li>STRUCTURES TOPSIDE MODULES</li>
                        <br></br>
                        <li>SHALLOW WATER JACK-UP RIG</li>
                        <br></br>
                        <li>SATURATION DIVING SYSTEMS, ROV LAUNCH & RECOVERY</li>
                        <br></br>
                        <li>SYSTEMS GAS COMPRESSORS & OTHER PROCESS MODULES </li>
                        <br></br>
                        <li>LIQUID MUD PLANTS BULKING SYSTEMS, ASME ‘U’ STAMP</li>
                        <br></br>
                        <li>PRESSURE VESSELS</li>
                        <br></br>
                        <li>SHIP BARGING</li>
                        <br></br>
                        <li>INSULATION AND DUCTING</li>
                        <br></br>
                        <li>STORAGE TANKS CATERING TO OIL & GAS, MARINE, PETROCHEMICAL AND OTHER SECTORS</li>
                        <br></br>
                        <li>ALL SHIPBUILDING ENGINEERING WORKS, STRUCTURE/PIPING/OUTFITTING WORKS, SHIP REPAIR</li>
                        <br></br>
                        <li>MECHANICAL WORKS (SPECIALIST IN MAIN ENGINE), ELECTRICAL & INSTRUMETATIONS WORKS</li>
                        <br></br>
                        <li>PROJECT VESSEL COMMISSIONING JOB, STAGING JOB, TANK CLEANING JOB, IPL & OPL SERVICES</li>
                        <br></br>
                        <li>ANCHOR CHAIN AND ANCHOR REMOVE AND NEW INSTALLATION</li>
                        <br></br>
                        <li>SHIP MANAGEMENT SERVICES</li>
                        <br></br>
                        <li>Tank Cleaning</li>
                        <br></br>
                        <li>Oil Transfer Ship to Ship</li>
                        <br></br>
                        <li>Desloping IPL and OPL</li>
                        <br></br>
                        <li>Blasing And Painting own Chamber</li>
                        <br></br>
                        <li>Valve Replacing and Calibration</li>
                        <br></br>
                        <li>Valves Change Rubber Sitting Repair</li>
                      </ul>
                  </div>
                  
              </div>

              <div className='bg-white rounded-xl shadow-2xl'>
                  <div className='p-8'>
                      
                      <h3 className='font-bold text-xl my-6'>COUNTRIES</h3>
                      <p className='text-gray-600 text-lg '>SINGAPORE, INDIA, CHINA, BANGLADESH,  INDONESIA</p>
                     
                  </div>
                  
              </div>
  
 
          </div>
      </div>
  </div>
  </section>
  );
};

export default Profile;
